// useAlert.js
import { ref } from 'vue';
import { toast } from 'vue3-toastify';

const alert = ref({ type: 'info', message: '' });
const showAlert = ref(false);

export default function useAlert() {
  function setAlert({ type, message }) {
    alert.value = { type, message };

    if (type !== 'error' && type !== 'success' && type !== 'warning' && type !== 'info') {
      type = 'default';
    }

    toast(message, {
      toastId: message,
      theme: 'light',
      type,
      transition: 'slide',
      dangerouslyHTMLString: true,
    });
  }

  return {
    alert,
    showAlert,
    setAlert,
  };
}
